import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import get from "lodash/get";

import BaseLayout from "../components/base-layout";
import Container from "../components/container";
import Content from "../components/content";
import PostHeader from "../components/post-header";
import Spacer from "../components/spacer";
import { rhythm } from "../utils/typography";
import { getBodyStyle } from "../utils/styles";
import colors from "../utils/colors";

class SubtopicTemplate extends React.Component {
  render() {
    const subtopic = this.props.data.markdownRemark;
    const posts = get(this, "props.data.allMarkdownRemark.edges", []);
    const pageLinks = [];
    posts.forEach(post => {
      if (post.node.path !== "/404/") {
        pageLinks.push(
          <li
            key={post.node.fields.path}
            style={{
              marginBottom: rhythm(1 / 4),
            }}
          >
            <Link
              style={{ boxShadow: "none", color: colors.white }}
              to={post.node.fields.path}
            >
              {post.node.frontmatter.title}
            </Link>
          </li>,
        );
      }
    });

    return (
      <BaseLayout>
        <Helmet title={subtopic.frontmatter.heading}>
          <body
            css={{
              ...getBodyStyle(subtopic.frontmatter.slug),
            }}
          />
        </Helmet>
        <Container>
          <PostHeader
            title={subtopic.frontmatter.heading}
            subtitle={subtopic.frontmatter.subheading}
            rhythmWidth={24}
          />
        </Container>
        <Spacer size={2} />
        <Container css={{ gridTemplateColumns: `1fr 1fr`, gridGap: rhythm(4) }}>
          <Content css={{ color: colors.white }} html={subtopic.html} />
        </Container>
        <Spacer size={1} />
        <Container>
          <ul>{pageLinks}</ul>
        </Container>
      </BaseLayout>
    );
  }
}

export default SubtopicTemplate;

export const pageQuery = graphql`
  query SubtopicBySlug($slug: String!) {
    markdownRemark(
      frontmatter: { slug: { eq: $slug } }
      fields: { sourceInstanceName: { eq: "subtopics" } }
    ) {
      id
      html
      frontmatter {
        name
        slug
        heading
        subheading
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { draft: { ne: true }, subtopics: { in: [$slug] } }
        fields: { sourceInstanceName: { eq: "posts" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            path
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
